/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from 'react'
 import PropTypes from 'prop-types'
 import {Helmet} from './helmet'
 
 const SEO = ({description, meta, title, appName, author, lang, langs, keywords, images, location}) => {
   const titleTemplate = appName ? `${appName} | %s` : ''
   const titleWithAuthor = author ? `${author} | ${title}` : title
   const url = location?.href ?? null

   let tempMeta = [
   {
      name: `keywords`,
      content: keywords
   }, 
   {
      name: `description`,
      content: description
   }, 
   {
      property: `og:title`,
      content: titleWithAuthor
   },
   {
      property: `og:description`,
      content: description
   },
   {
      property: `og:site_name`,
      content: titleWithAuthor
   },
   {
      property: `og:type`,
      content: `website`
   },
   {
      name: `twitter:card`,
      content: `summary`
   },
   {
      name: `twitter:title`,
      content: titleWithAuthor
   },
   {
      name: `twitter:description`,
      content: description
   },
 ]
   if (author) {
     tempMeta.push({
       name: `twitter:creator`,
       content: author
     })
   }
   if (images && Array.isArray(images)) {
    images.forEach((img) => {
      tempMeta.push({
        property: `og:image`,
        content: img.url
     })

     if (img.width) {
      tempMeta.push({
        property: `og:image:width`,
        content: img.width
      })
     }

     if (img.height) {
      tempMeta.push({
        property: `og:image:height`,
        content: img.height
      })
     }
 
     tempMeta.push({
       name: `twitter:image`,
       content: img.url
     })
    })  
   }

   if (lang && langs) {
    const metaLangs = langs.map(lng => ({
      property: `og:locale${lng === lang ? '' : ':alternate'}`,
      content: lng
     }))
     tempMeta.push(...metaLangs)
   }

   if (url) {
    tempMeta.push({
      property: `og:url`,
      content: url
    })
    tempMeta.push({
      property: `twitter:url`,
      content: url
    })
   }

   if (Array.isArray(meta)) {
    tempMeta = tempMeta.concat(meta)
   }

   return (
     <Helmet
       location={location}
       title={title}
       titleTemplate={titleTemplate}
     >
      {
        tempMeta.map(m =>  <meta key={m.name || m.property} {...m} />)
      }
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/manifest.json" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="fo-verify" content="58e30e14-9fc9-4de5-b868-045b6e443f81" />
      <meta name='impact-site-verification' value='306d7f7e-1211-46ed-91ad-e6c813a843af'/>
      <meta name="OMG-Verify-V1" content="40d7ebac-df53-4819-813b-5002aaa4bdbf"/>
      
     </Helmet>
   )
 }
 
 SEO.defaultProps = {
   meta: [],
   lang: null,
   appName: null,
   author: null,
 }
 
 SEO.propTypes = {
   appName: PropTypes.string,
   description: PropTypes.string.isRequired,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string.isRequired,
   author: PropTypes.string,
 }
 
 export default SEO