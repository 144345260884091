import React from 'react'
import { Head } from 'gatsby'
import SEO from 'components/presenter/seo/seo'
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import LoadingBackdrop from 'components/presenter/form/LoadingBackdrop'
import cn from 'classnames'
import * as styles from './page.module.css'

const Page = ({
  children,
  location,
  isLoading = false,
  seoMeta = {},
  className = '',
}) => {
  const { t } = useTranslation()
  const { language, languages } = useI18next()
  const { title, meta, appName, author, description, images } = seoMeta
  
  const bannerLink = () => `${location.origin}/images/share.jpg`

  return (
    <>
      <SEO 
        title={title || t('seo.title')}
        meta={meta}
        appName={appName || t('common.appName')}
        author={author || t('seo.author')}
        description={description || t('seo.description')}
        lang={language}
        langs={languages}
        images={images || (location ? [{
          url: bannerLink(),
          width: 1200,
          height: 457
        }] : null)}
        location={location}
        keywords='coyns, cashback, cashback offers, cashback rewards, coyns cashback, digital wallet, reward points, loyalty program, consumer rewards, shopping rewards, cashback apps, cashback websites, best cashback, cashback deals, coyns savings, financial incentives, 荷包, 現金回贈, 獎勵積分, 忠誠計劃, 消費者獎勵, 購物獎勵, 現金回贈應用, 現金回贈網站, 最佳現金回贈, 現金回贈交易, 荷包儲蓄, 財務激勵'
      />
      <div className={cn(styles.root, className)}>
        { children }
        <LoadingBackdrop className={styles.loader} open={isLoading} />
      </div>
    </>
  )
}
export default Page